import axios from 'axios'
export default {
  async store_rotas_usuario_buscar({ commit }, payload) {
    try {
      const data = await axios.get('rotas/usuario', { params: payload }, {})
      commit('CHANGE_ROTAS_USUARIO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_rotas_usuario_por_id({ commit }, id) {
    try {
      const data = await axios.get(`rotas/usuario/${id}`)
      commit('CHANGE_ROTAS_USUARIO_POR_ID', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_rotas_usuario_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`rotas/usuario/${payload.usuario_id}`, payload, {})
      console.log(commit)
      return data
    } catch (e) {
      return e
    }
  }
}
