import { CHANGE_USUARIOS } from './mutation-types'
import { CHANGE_USUARIO } from './mutation-types'
export default {
  [CHANGE_USUARIOS](state, payload) {
    state.usuarios = payload
  },
  [CHANGE_USUARIO](state, payload) {
    state.usuario = payload
  }
}
