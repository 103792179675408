import axios from 'axios'
export default {
  async store_usuarios_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('auth/all-users', { params: payload }, {})
      commit('CHANGE_USUARIOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_usuarios_find_user({ commit }, idusuario) {
    try {
      const { data } = await axios.get(`auth/user/${idusuario}`, {})
      commit('CHANGE_USUARIO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_usuarios_update_user({ commit }, payload) {
    try {
      const { data } = await axios.post(`auth/update`, payload, {})
      commit('CHANGE_USUARIOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_usuarios_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`auth/user/${id}`, {})
      commit('CHANGE_CLIENTES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_usuarios_pesquisar({ commit }, payload) {
    try {
      const { data } = await axios.get('usuarios/pesquisar', { params: payload }, {})
      commit('CHANGE_USUARIOS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
