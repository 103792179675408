import axios from 'axios'
export default {
  async store_servicos_buscar({ commit }, payload) {
    try {
      const data = await axios.get('servicos', { params: payload }, {})
      commit('CHANGE_SERVICOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_servicos_find({ commit }, id) {
    try {
      const { data } = await axios.get(`servicos/${id}`, {})
      commit('CHANGE_SERVICOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_servicos_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`servicos`, payload, {})
      commit('CHANGE_SERVICOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_servicos_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`servicos/${payload.id}`, payload, {})
      commit('CHANGE_SERVICOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_servicos_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`servicos/${id}`, {})
      commit('CHANGE_SERVICOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_servicos_consulta_cnpj({ commit }, payload) {
    try {
      const data = await axios.get('servicos/consulta/dados-cnpj', { params: payload }, {})
      if (commit) {
        return data
      }
      return data
    } catch (e) {
      return e
    }
  }
}
