import axios from 'axios'
export default {
  async store_programas_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('programa', { params: payload }, {})
      commit('CHANGE_PROGRAMAS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_programas_find({ commit }, id) {
    try {
      const { data } = await axios.get(`programa/${id}`, {})
      commit('CHANGE_PROGRAMAS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_programas_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`programa`, payload, {})
      commit('CHANGE_PROGRAMAS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_programas_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`programa/${payload.id}`, payload, {})
      commit('CHANGE_PROGRAMAS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_programas_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`programa/${id}`, {})
      commit('CHANGE_PROGRAMAS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
