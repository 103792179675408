<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M20.289 12.6593L11.811 12.6593C11.4188 12.6593 11.1005 12.341 11.1005 11.9488C11.1005 11.5566 11.4188 11.2383 11.811 11.2383L20.289 11.2383C20.6812 11.2383 20.9995 11.5566 20.9995 11.9488C20.9995 12.341 20.6812 12.6593 20.289 12.6593Z" fill="currentColor"></path>
    <mask id="mask1" maskUnits="userSpaceOnUse" x="3" y="6" width="10" height="12">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5215 6.50024L12.5215 17.3966H3.58202L3.58202 6.50024L12.5215 6.50024Z" fill="white"></path>
    </mask>
    <g mask="url(#mask0)">
      <path d="M11.811 17.3969C11.6783 17.3969 11.5476 17.36 11.432 17.288L3.9137 12.5502C3.70717 12.4195 3.58117 12.193 3.58117 11.9486C3.58117 11.7042 3.70717 11.4778 3.9137 11.347L11.432 6.60924C11.6509 6.47187 11.9275 6.46335 12.1539 6.58935C12.3813 6.7144 12.5215 6.95219 12.5215 7.21082L12.5215 16.6864C12.5215 16.945 12.3813 17.1828 12.1539 17.3079C12.0469 17.3676 11.9284 17.3969 11.811 17.3969Z" fill="currentColor"></path>
    </g>
  </svg>
</template>
