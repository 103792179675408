import { createRouter, createWebHistory } from 'vue-router'
import routerUsuarios from './modules-routes/usuarios'
import routerRotasUsuario from './modules-routes/rotasUsuario'
import chatRoutes from './modules-routes/chat'
import configuracaoLowCodeRoutes from './modules-routes/configuracaoLowCode'

import Page404 from '../views/erros/Page404.vue'

const routes = [
  {
    path: '/login',
    name: 'auth.login',
    meta: { name: 'Login', page: 'login' },
    component: () => import('@/views/auth/SignIn.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    meta: { auth: true, page: 'home' },
    component: () => import('../layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'dashboard.home',
        meta: {
          name: 'Home',
          page: 'home',
          tagTitlePage: 'titleHome',
          tagDescriptionPage: 'descriptionHome',
          isBanner: true,
          descricaoPagina: 'Home',
          permissioes: {
            grupoRota: 'dashboard.home',
            tipoRota: 'listar'
          }
        },
        component: () => import('@/views/dashboards/IndexPage.vue')
      }
    ]
  },
  ...routerUsuarios,
  ...routerRotasUsuario,
  ...chatRoutes,
  ...configuracaoLowCodeRoutes,

  { path: '/:pathMatch(.*)*', component: Page404 }
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

export default (app) => {
  app.router = router
  app.use(router)
}
