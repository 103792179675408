import axios from 'axios'
export default {
  async store_clientes_buscar({ commit }, payload) {
    try {
      const data = await axios.get('clientes', { params: payload }, {})
      commit('CHANGE_CLIENTES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_clientes_find({ commit }, id) {
    try {
      const { data } = await axios.get(`clientes/${id}`, {})
      commit('CHANGE_CLIENTES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_clientes_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`clientes`, payload, {})
      commit('CHANGE_CLIENTES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_clientes_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`clientes/${payload.id}`, payload, {})
      commit('CHANGE_CLIENTES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_clientes_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`clientes/${id}`, {})
      commit('CHANGE_CLIENTES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_clientes_consulta_cnpj({ commit }, payload) {
    try {
      const data = await axios.get('clientes/consulta/dados-cnpj', { params: payload }, {})
      if (commit) {
        return data
      }
      return data
    } catch (e) {
      return e
    }
  }
}
