<template>
  <div></div>
</template>
<script>
export default {
  name: 'pageNotFound',
  created() {
    if (this.$auth.check()) {
      this.$router.push('/')
    } else {
      this.$router.push('/login')
    }
  }
}
</script>
