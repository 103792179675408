import axios from 'axios'
export default {
  async store_igp_di_acumulado_buscar({ commit }, payload) {
    try {
      const data = await axios.get('igp-di-acumulado', { params: payload }, {})
      commit('CHANGE_IGP_DI_ACUMULADO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igp_di_acumulado_find({ commit }, id) {
    try {
      const { data } = await axios.get(`igp-di-acumulado/${id}`, {})
      commit('CHANGE_IGP_DI_ACUMULADO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igp_di_acumulado_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`igp-di-acumulado`, payload, {})
      commit('CHANGE_IGP_DI_ACUMULADO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igp_di_acumulado_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`igp-di-acumulado/${payload.id}`, payload, {})
      commit('CHANGE_IGP_DI_ACUMULADO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igp_di_acumulado_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`igp-di-acumulado/${id}`, {})
      commit('CHANGE_IGP_DI_ACUMULADO', data)
      return data
    } catch (e) {
      return e
    }
  }
}
