import axios from 'axios'
export default {
  async store_prospects_buscar({ commit }, payload) {
    try {
      const data = await axios.get('prospects', { params: payload }, {})
      commit('CHANGE_PROSPECTS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_prospects_find({ commit }, id) {
    try {
      const { data } = await axios.get(`prospects/${id}`, {})
      commit('CHANGE_PROSPECTS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_prospects_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`prospects`, payload, {})
      commit('CHANGE_PROSPECTS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_prospects_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`prospects/${payload.id}`, payload, {})
      commit('CHANGE_PROSPECTS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_prospects_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`prospects/${id}`, {})
      commit('CHANGE_PROSPECTS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
