const dashboardChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix,
    meta: {
      auth: true,
      name: 'rotasUsuarios',
      page: 'rotasUsuarios',
      tagTitlePage: 'titlerotasUsuarios',
      tagDescriptionPage: 'descriptionrotasUsuarios',
      isBanner: true,
      descricaoPagina: 'Adicionar/Editar Rotas do Usuário',
      permissioes: {
        grupoRota: prefix,
        tipoRota: 'listar'
      }
    },
    component: () => import('@/views/dashboards/rotasUsuarios/RotasUsuario-listar.vue')
  }
]
export default [
  {
    path: '/rotas-usuarios/:id',
    name: 'dashboard.rotasUsuarios.default',
    meta: { auth: true },
    component: () => import('../../layouts/DefaultLayout.vue'),
    children: dashboardChildRoutes('dashboard.rotasUsuarios')
  }
]
