<template>
  <!--Logo start-->
  <div class="logo-main">
    <div class="logo-normal">
      <svg :class="`${color ? 'text-primary' : ''} icon-30`" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M265 308 c-3 -13 -4 -81 -3 -152 3 -112 5 -130 21 -140 15 -9 21 -7 33 8 17 24 20 256 4 287 -15 26 -48 25 -55 -3z"></path>
          <path
            d="M187 264 c-4 -4 -7 -60 -7 -125 0 -99 3 -118 16 -123 37 -14 45 11
               42 133 -3 108 -4 116 -23 119 -12 2 -24 0 -28 -4z"></path>
          <path
            d="M97 194 c-4 -4 -7 -42 -7 -84 0 -81 13 -109 45 -96 13 5 15 22 13 93
               -3 80 -5 88 -23 91 -12 2 -24 0 -28 -4z"></path>
          <path
            d="M23 143 c-20 -7 -17 -122 2 -129 27 -11 45 16 45 67 0 49 -18 72 -47
               62z"></path>
        </g>
      </svg>
    </div>
    <div class="logo-mini">
      <svg :class="`${color ? 'text-primary' : ''} icon-30`" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M265 308 c-3 -13 -4 -81 -3 -152 3 -112 5 -130 21 -140 15 -9 21 -7 33 8 17 24 20 256 4 287 -15 26 -48 25 -55 -3z"></path>
          <path
            d="M187 264 c-4 -4 -7 -60 -7 -125 0 -99 3 -118 16 -123 37 -14 45 11
               42 133 -3 108 -4 116 -23 119 -12 2 -24 0 -28 -4z"></path>
          <path
            d="M97 194 c-4 -4 -7 -42 -7 -84 0 -81 13 -109 45 -96 13 5 15 22 13 93
               -3 80 -5 88 -23 91 -12 2 -24 0 -28 -4z"></path>
          <path
            d="M23 143 c-20 -7 -17 -122 2 -129 27 -11 45 16 45 67 0 49 -18 72 -47
               62z"></path>
        </g>
      </svg>
    </div>
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  props: {
    color: { type: Boolean, default: false }
  }
}
</script>
