import axios from 'axios'
export default {
  async store_processos_buscar({ commit }, payload) {
    try {
      const data = await axios.get('processos', { params: payload }, {})
      commit('CHANGE_PROCESSOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_find({ commit }, id) {
    try {
      const { data } = await axios.get(`processos/${id}`, {})
      commit('CHANGE_PROCESSOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`processos`, payload, {})
      commit('CHANGE_PROCESSOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`processos/${payload.id}`, payload, {})
      commit('CHANGE_PROCESSOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`processos/${id}`, {})
      commit('CHANGE_PROCESSOS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
