<template>
  <router-view />
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import '@/plugins/styles'
export default {
  name: 'App',
  setup() {
    const store = useStore()
    store.dispatch('setting/setSetting')
    const sidebarType = computed(() => store.getters['setting/sidebar_type'])
    const resizePlugin = () => {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('on-resize')
            store.dispatch('setting/sidebar_type', [...sidebarType.value, 'sidebar-mini'])
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            sidebarResponsive.classList.remove('on-resize')
            store.dispatch(
              'setting/sidebar_type',
              sidebarType.value.filter((item) => item !== 'sidebar-mini')
            )
          }
        }
      }
    }
    onMounted(() => {
      window.addEventListener('resize', resizePlugin)
      setTimeout(() => {
        resizePlugin()
      }, 200)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizePlugin)
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';
</style>
<style>
@import 'view-ui-plus/dist/styles/viewuiplus.css';
.ivu-input-search {
  background: #ffffff !important;
  color: #515a6e !important;
  border-color: #dee2e6 !important;
  z-index: 0;
}
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
  width: 100%;
}
table.dataTable.table-striped.DTFC_Cloned tbody tr:nth-of-type(odd) {
  background: #f3f3f3;
}

table.dataTable.table-striped.DTFC_Cloned tbody tr:nth-of-type(even) {
  background: white;
}
div.dataTables_scrollBody thead {
  display: none;
}

.dtfc-fixed-right-1 {
  color: #0b112e !important;
}
.dtfc-fixed-right-2 {
  color: #0b112e !important;
}
thead th {
  color: #0b112e !important;
}

tbody td {
  color: #0b112e !important;
}
.sidebarchat {
  margin-top: 10vh;
  display: block;
  position: fixed;
  width: 25%;
  top: 0;
  bottom: 0;
  transition: var(--sidebar-transition);
  transition-duration: var(--sidebar-transition-duration);
  transition-timing-function: var(--sidebar-transition-function-ease);
  padding: 10px;
  background-color: #fff;
  color: var(--bs-primary);
  box-shadow: 0 0 30px rgba(var(--bs-primary-rgb), 0.05);
}
sidebarchat.left-bordered:not(.sidebar-color) .nav-item:not(.static-item) .nav-link.active,
.sidebarchat.left-bordered:not(.sidebar-color) .nav-item:not(.static-item) .nav-link[aria-expanded='true'],
.sidebarchat.left-bordered:not(.sidebar-color) .nav-item:not(.static-item) .nav-link:hover {
  color: var(--bs-primary);
}

.sidebarchat.sidebar-default .nav-link:not(.static-item).active,
.sidebarchat.sidebar-default .nav-link:not(.static-item)[aria-expanded='true'] {
  background: var(--bs-primary);
  color: #fff;
  box-shadow: 0 10px 20px -10px rgba(var(--shadow-dark-color), 38%);
}
</style>
