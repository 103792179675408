import axios from 'axios'
export default {
  async store_fornecedores_buscar({ commit }, payload) {
    try {
      const data = await axios.get('fornecedores', { params: payload }, {})
      commit('CHANGE_FORNECEDORES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fornecedores_find({ commit }, id) {
    try {
      const { data } = await axios.get(`fornecedores/${id}`, {})
      commit('CHANGE_FORNECEDORES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fornecedores_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`fornecedores`, payload, {})
      commit('CHANGE_FORNECEDORES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fornecedores_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`fornecedores/${payload.id}`, payload, {})
      commit('CHANGE_FORNECEDORES', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fornecedores_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`fornecedores/${id}`, {})
      commit('CHANGE_FORNECEDORES', data)
      return data
    } catch (e) {
      return e
    }
  }
}
