import axios from 'axios'
export default {
  async store_grupo_fatores_desconto_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('grupo-fatores-desconto', { params: payload }, {})
      commit('CHANGE_GRUPO_FATORES_DESCONTO', data)
      return data
    } catch (e) {
      return e
    }
  }
}
