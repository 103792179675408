import axios from 'axios'
export default {
  async store_igpd_pi_buscar({ commit }, payload) {
    try {
      const data = await axios.get('igp-di', { params: payload }, {})
      commit('CHANGE_IGPD_PI', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igpd_pi_find({ commit }, id) {
    try {
      const { data } = await axios.get(`igp-di/${id}`, {})
      commit('CHANGE_IGPD_PI', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igpd_pi_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`igp-di`, payload, {})
      commit('CHANGE_IGPD_PI', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igpd_pi_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`igp-di/${payload.id}`, payload, {})
      commit('CHANGE_IGPD_PI', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_igpd_pi_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`igp-di/${id}`, {})
      commit('CHANGE_IGPD_PI', data)
      return data
    } catch (e) {
      return e
    }
  }
}
