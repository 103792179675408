import axios from 'axios'
export default {
  async store_fatores_desconto_buscar({ commit }, payload) {
    try {
      const data = await axios.get('fatores-desconto', { params: payload }, {})
      commit('CHANGE_FATORES_DESCONTO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fatores_desconto_find({ commit }, id) {
    try {
      const { data } = await axios.get(`fatores-desconto/${id}`, {})
      commit('CHANGE_FATORES_DESCONTO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fatores_desconto_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`fatores-desconto`, payload, {})
      commit('CHANGE_FATORES_DESCONTO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fatores_desconto_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`fatores-desconto/${payload.id}`, payload, {})
      commit('CHANGE_FATORES_DESCONTO', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_fatores_desconto_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`fatores-desconto/${id}`, {})
      commit('CHANGE_FATORES_DESCONTO', data)
      return data
    } catch (e) {
      return e
    }
  }
}
