import axios from 'axios'
export default {
  async store_tipo_tributacao_enquadramento_empresa_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('tipo-tributacao/enquadramento-empresa', { params: payload }, {})
      commit('CHANGE_ENQUADRAMENTO_EMPRESA', data)
      return data
    } catch (e) {
      return e
    }
  }
}
