import axios from 'axios'
export default {
  async store_ramo_atividade_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('ramo-atividade', payload, {})
      commit('CHANGE_RAMO_ATIVIDADE', data)
      return data
    } catch (e) {
      return e
    }
  }
}
