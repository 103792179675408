import axios from 'axios'
export default {
  async store_correios_consulta_cep({ commit }, payload) {
    try {
      const data = await axios.get('correios/consultar-cep', { params: payload }, {})
      commit('CHANGE_CORREIOS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
