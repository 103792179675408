import { CHANGE_CATEGORIAS } from './mutation-types'
import { CHANGE_CATEGORIAS_DOCUMENTOS } from './mutation-types'

export default {
  [CHANGE_CATEGORIAS](state, payload) {
    state.categorias = payload
  },
  [CHANGE_CATEGORIAS_DOCUMENTOS](state, payload) {
    state.categoriasDocumentos = payload
  }
}
