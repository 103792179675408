const dashboardChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix,
    meta: {
      auth: true,
      name: 'rotasUsuarios',
      page: 'rotasUsuarios',
      tagTitlePage: 'titlerotasUsuarios',
      tagDescriptionPage: 'descriptionrotasUsuarios',
      isBanner: false,
      isFooter: false,
      descricaoPagina: 'Adicionar/Editar Rotas do Usuário',
      permissioes: {
        grupoRota: prefix,
        tipoRota: 'listar'
      }
    },
    component: () => import('@/views/dashboards/chat/ConfLowCode.vue')
  }
]
export default [
  {
    path: '/fluxo/atendimento',
    name: 'dashboard.configuracaoLowCodeRoutes.default',
    meta: { auth: true },
    component: () => import('../../layouts/DefaultLayout.vue'),
    children: dashboardChildRoutes('dashboard.configuracaoLowCodeRoutes')
  }
]
