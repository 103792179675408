const dashboardChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.users',
    meta: {
      auth: true,
      name: 'Usuários',
      page: 'users',
      tagTitlePage: 'titleUsers',
      tagDescriptionPage: 'descriptionUsers',
      isBanner: true,
      descricaoPagina: 'Listagem de Usuários',
      permissioes: {
        grupoRota: prefix,
        tipoRota: 'listar'
      }
    },
    component: () => import('@/views/dashboards/users/Users-list.vue')
  },
  {
    path: '/usuarios/cadastro',
    name: prefix + '.users-add',
    meta: {
      auth: true,
      name: 'Usuários',
      page: 'users',
      tagTitlePage: 'titleUsersAdd',
      tagDescriptionPage: 'descriptionUsersAdd',
      isBanner: true,
      descricaoPagina: 'Cadastro de Usuário',
      permissioes: {
        grupoRota: prefix,
        tipoRota: 'cadastrar'
      }
    },
    component: () => import('@/views/dashboards/users/Users-Add.vue')
  },
  {
    path: '/usuarios/editar/:id',
    name: prefix + '.users-edit',
    meta: {
      auth: true,
      name: 'Usuários',
      page: 'users',
      tagTitlePage: 'titleUsersAdd',
      tagDescriptionPage: 'descriptionUsersAdd',
      isBanner: true,
      descricaoPagina: 'Edição de Usuário',
      permissioes: {
        grupoRota: prefix,
        tipoRota: 'editar'
      }
    },
    component: () => import('@/views/dashboards/users/Users-Edit.vue')
  }
]
export default [
  {
    path: '/usuarios',
    name: 'dashboard.usuarios',
    meta: { auth: true },
    component: () => import('../../layouts/DefaultLayout.vue'),
    children: dashboardChildRoutes('dashboard.usuarios')
  }
]
