import axios from 'axios'
import { Notice, LoadingBar } from 'view-ui-plus'

const exibirErrorRequest = (resp) => {
  try {
    if (resp) {
      if (typeof resp.data === 'object') {
        if (typeof resp.data.sucesso != 'undefined') {
          if (!resp.data.sucesso) {
            let erros = []
            if (resp.data.erros) erros = resp.data.erros
            else erros = ['Não foi possivel realizar está operação']
            erros.forEach((item) => {
              Notice.error({
                title: 'Error! ',
                desc: item
              })
            })
          }
        } else if (typeof resp.data.erros != 'undefined') {
          let erros = []
          if (resp.data.erros) {
            if (resp.data.erros.messages) {
              erros = resp.data.erros.messages
            } else {
              erros = resp.data.erros
            }
          } else {
            erros = ['Não foi possivel realizar está operação']
          }
          try {
            erros.forEach((item) => {
              Notice.error({
                title: 'Error! ',
                desc: item
              })
            })
          } catch (error) {
            Notice.error({
              title: 'Error! ',
              desc: 'Não foi possivel realizar está operação'
            })
          }
        }
      }
    } else {
      Notice.error({
        title: 'Error! ',
        desc: 'Resposta não esperada do servidor'
      })
    }
  } catch (e) {
    Notice.error({
      title: 'Error! ',
      desc: e
    })
  }
}

const openRequest = () => {
  axios.interceptors.request.use(
    function (config) {
      LoadingBar.start()
      return config
    },
    function (error) {
      LoadingBar.error()
      return Promise.reject(error)
    }
  )
}

const finallyRequest = () => {
  axios.interceptors.response.use(
    function (resp) {
      exibirErrorRequest(resp)
      LoadingBar.finish()
      return resp
    },
    function (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message)
        Notice.error({
          title: 'Error! ',
          desc: 'Não foi possivel realizar está operação'
        })
      } else {
        exibirErrorRequest(error.response)
        LoadingBar.error()
        return Promise.reject(error)
      }
    }
  )
}

export default (app) => {
  axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8005/api/' : 'https://app.lideredigital.com.br/api/'
  app.axios = axios
  app.$http = axios
  app.config.globalProperties.axios = axios
  app.config.globalProperties.$http = axios
  openRequest()
  finallyRequest()
}
