import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n.js'
import configAxios from '@/plugins/configAxios.js'
import auth from '@/plugins/auth.js'
// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import CounterUp from 'vue3-autocounter'
import VueTheMask from 'vue-the-mask'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
require('waypoints/lib/noframework.waypoints.min')

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons'

const app = createApp(App)
app.use(store).use(router).use(i18n)

for (const [key, component] of Object.entries(Icons)) {
  app.component(key, component)
}

// Library Components
app.use(VueSweetalert2)
app.use(VueApexCharts)
app.use(BootstrapVue3)
app.component('counter-up', CounterUp)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)
app.use(configAxios)
app.use(auth)
app.use(VueTheMask)

app.use(ElementPlus)
app.use(Icons)
app.mount('#app')

export default app
