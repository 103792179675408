import { createStore, createLogger } from 'vuex'
import setting from './setting'
import usuarios from './modules/usuarios'
import clientes from './modules/clientes'
import programas from './modules/programas'
import ramoAtividades from './modules/ramoAtividades'
import tipoContribuinteICMS from './modules/tipoContribuinteICMS'
import correios from './modules/correios'
import prospects from './modules/prospects'
import fornecedores from './modules/fornecedores'
import servicos from './modules/servicos'
import categoriaSubcategoria from './modules/categoriaSubcategoria'
import fatoresDesconto from './modules/fatoresDesconto'
import gruposFatoresDesconto from './modules/gruposFatoresDesconto'
import igpdPi from './modules/igpdPi'
import igpDiAcumulado from './modules/igpDiAcumulado'
import processos from './modules/processos'
import processosAcompanhamentos from './modules/processosAcompanhamentos'
import processosAnexos from './modules/processosAnexos'
import rotasUsuario from './modules/rotasUsuario'
import tipoTributacaoEnquadramentoEmpresa from './modules/tipoTributacaoEnquadramentoEmpresa'

const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  state: {
    lang: 'pt',
    signInPopup: false,
    signUpPopup: false,
    shareOffcanvas: false,
    permissoesRotas: {
      listar: true,
      cadastrar: true,
      editar: true,
      excluir: true
    },
    arraySimNao: [
      {
        id: 1,
        descricao: 'Sim'
      },
      {
        id: 0,
        descricao: 'Não'
      }
    ],
    status: [
      {
        id: 1,
        descricao: 'Ativo'
      },
      {
        id: 0,
        descricao: 'Inativo'
      }
    ],
    tipoInscricao: [
      {
        id: 1,
        descricao: 'Pessoa Física'
      },
      {
        id: 2,
        descricao: 'Pessoa Jurídica'
      }
    ],
    grupoUsuario: [
      {
        id: 1,
        descricao: 'Administradores'
      },
      {
        id: 2,
        descricao: 'Usuários'
      }
    ],
    tipoProjeto: [
      {
        id: 'expansão',
        descricao: 'Expansão'
      },
      {
        id: 'reenquadramento',
        descricao: 'Reenquadramento'
      },
      {
        id: 'implantação',
        descricao: 'Implantação'
      },
      {
        id: 're-localização',
        descricao: 'Re-localização'
      }
    ],
    statusProcessos: [
      {
        id: 'em andamento',
        descricao: 'Em andamento'
      },
      {
        id: 'finalizado',
        descricao: 'Finalizado'
      }
    ],
    orgaoProcessos: [
      {
        id: 'SEFAZ - GTRE',
        descricao: 'SEFAZ - GTRE'
      },
      {
        id: 'SEFAZ - SUREC',
        descricao: 'SEFAZ - SUREC'
      },
      {
        id: 'EMPRESA',
        descricao: 'EMPRESA'
      }
    ]
  },
  getters: {
    lang: (state) => state.lang,
    carts: (state) => state.dropdowns.carts,
    signInPopup: (state) => state.signInPopup,
    signUpPopup: (state) => state.signUpPopup,
    shareOffcanvas: (state) => state.shareOffcanvas,
    permissoesRotas: (state) => state.permissoesRotas
  },
  mutations: {
    langChangeCommit(state, payload) {
      state.lang = payload
    },
    openModalCommit(state, payload) {
      state[payload.name] = payload.value
    },
    openBottomCanvasCommit(state, payload) {
      state[payload.name] = payload.value
    },
    permissoesRotaCommit(state, payload) {
      state[payload.name] = payload.value
    }
  },
  actions: {
    openModalAction({ commit }, payload) {
      commit('openModalCommit', payload)
    },
    openBottomCanvasAction({ commit }, payload) {
      commit('openBottomCanvasCommit', payload)
    },
    langChangeAction(context, payload) {
      context.commit('langChangeCommit', payload)
    },
    permissoesRotaAction(context, payload) {
      context.commit('permissoesRotaCommit', payload)
    }
  },
  modules: {
    setting: setting,
    usuarios: usuarios,
    clientes: clientes,
    programas: programas,
    ramoAtividades: ramoAtividades,
    tipoContribuinteICMS: tipoContribuinteICMS,
    correios: correios,
    prospects: prospects,
    fornecedores: fornecedores,
    servicos: servicos,
    categoriaSubcategoria: categoriaSubcategoria,
    fatoresDesconto: fatoresDesconto,
    gruposFatoresDesconto: gruposFatoresDesconto,
    igpdPi: igpdPi,
    igpDiAcumulado: igpDiAcumulado,
    processos: processos,
    processosAcompanhamentos: processosAcompanhamentos,
    processosAnexos: processosAnexos,
    rotasUsuario: rotasUsuario,
    tipoTributacaoEnquadramentoEmpresa: tipoTributacaoEnquadramentoEmpresa
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
