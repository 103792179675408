import axios from 'axios'
export default {
  async store_categorias_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('categorias', { params: payload }, {})
      commit('CHANGE_CATEGORIAS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_categorias_documentos_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('categorias/documentos', { params: payload }, {})
      commit('CHANGE_CATEGORIAS_DOCUMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
