import axios from 'axios'
export default {
  async store_processos_anexos_buscar({ commit }, payload) {
    try {
      const data = await axios.get('processos-anexos', { params: payload }, {})
      commit('CHANGE_PROCESSOS_ANEXOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_anexos_find({ commit }, id) {
    try {
      const { data } = await axios.get(`processos-anexos/${id}`, {})
      commit('CHANGE_PROCESSOS_ANEXOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_download_file({ commit }, id) {
    try {
      const url = `processos-anexos/download/file/${id}`

      const response = await axios.get(url, {
        responseType: 'blob',
        withCredentials: false
      })

      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const windowUrl = window.URL || window.webkitURL
      const file = windowUrl.createObjectURL(blob)

      const headerval = response.request.getResponseHeader('Content-Disposition') ? response.request.getResponseHeader('Content-Disposition') : 'file'
      let contentDisposition = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '')
      const fileName = decodeURI(contentDisposition)

      const a = document.createElement('a')
      a.setAttribute('download', fileName)
      a.setAttribute('href', file)
      a.click()

      commit('CHANGE_PROCESSOS_ANEXOS', '')
      return true
    } catch (e) {
      return e
    }
  },
  async store_processos_anexos_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`processos-anexos`, payload, {})
      commit('CHANGE_PROCESSOS_ANEXOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_anexos_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`processos-anexos/${payload.id}`, payload, {})
      commit('CHANGE_PROCESSOS_ANEXOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_anexos_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`processos-anexos/${id}`, {})
      commit('CHANGE_PROCESSOS_ANEXOS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
