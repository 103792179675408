import axios from 'axios'
export default {
  async store_tipo_contribuinte_icms_buscar({ commit }, payload) {
    try {
      const { data } = await axios.get('tipo-contribuinte-icms', payload, {})
      commit('CHANGE_TIPO_CONTRIBUINTE_ICMS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
