import { CHANGE_ROTAS_USUARIO } from './mutation-types'
import { CHANGE_ROTAS_USUARIO_POR_ID } from './mutation-types'

export default {
  [CHANGE_ROTAS_USUARIO](state, payload) {
    state.rotasUsuarioAutenticadoa = payload
  },
  [CHANGE_ROTAS_USUARIO_POR_ID](state, payload) {
    state.rotasUsuario = payload
  }
}
