import axios from 'axios'
export default {
  async store_processos_acompanhamentos_buscar({ commit }, payload) {
    try {
      const data = await axios.get('processos-acompanhamentos', { params: payload }, {})
      commit('CHANGE_PROCESSOS_ACOMPANHAMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_acompanhamentos_find({ commit }, id) {
    try {
      const { data } = await axios.get(`processos-acompanhamentos/${id}`, {})
      commit('CHANGE_PROCESSOS_ACOMPANHAMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_acompanhamentos_save({ commit }, payload) {
    try {
      const { data } = await axios.post(`processos-acompanhamentos`, payload, {})
      commit('CHANGE_PROCESSOS_ACOMPANHAMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_acompanhamentos_update({ commit }, payload) {
    try {
      const { data } = await axios.put(`processos-acompanhamentos/${payload.id}`, payload, {})
      commit('CHANGE_PROCESSOS_ACOMPANHAMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_acompanhamentos_patch({ commit }, payload) {
    try {
      const { data } = await axios.patch(`processos-acompanhamentos/${payload.id}`, payload, {})
      commit('CHANGE_PROCESSOS_ACOMPANHAMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  },
  async store_processos_acompanhamentos_excluir({ commit }, id) {
    try {
      const { data } = await axios.delete(`processos-acompanhamentos/${id}`, {})
      commit('CHANGE_PROCESSOS_ACOMPANHAMENTOS', data)
      return data
    } catch (e) {
      return e
    }
  }
}
